import React from "react"
import bg from "../img/bg/footer-bg.jpg"
import { Link } from "gatsby"
import logo from "../img/banner-trackmylifts-white.png"

class Footer extends React.Component {
  render() {
    return (
      <footer className="wow fadeInUp">
        <div
          className="footer-area pt-100 pb-100"
          style={{
            backgroundImage: "url(" + bg + ")",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="footer-text text-center">
                  <img src={logo} alt="" />
                  <div className="footer-icon mt-35 mb-30">
                    <a href="https://www.facebook.com/trackmylifts/">
                      <i className="ti-facebook"></i>
                    </a>
                    <a href="https://twitter.com/trackmylifts">
                      <i className="ti-twitter-alt"></i>
                    </a>
                    <a href="https://www.instagram.com/trackmylifts/">
                      <i className="ti-instagram"></i>
                    </a>
                  </div>
                  <p>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </p>
                  <p>
                    © Copyright {new Date().getFullYear()} | All right reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <h4>About</h4>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/about#why">Why</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 text-center">
                <h4>Product</h4>
                <ul>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/features">Features</Link>
                  </li>
                  <li>
                    <Link to="/download">Download</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 text-center">
                <h4>Resources</h4>
                <ul>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
