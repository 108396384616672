import React from "react"
import { Link } from "gatsby"
import logo from "../img/banner-trackmylifts-white.png"

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showMobileNav: false};
  }

  render() {
    return (
      <div
        id="sticky-header"
        className="header-area header-transparent pt-40 pb-40"
      >
        <div className="header-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-menu">
                  <div className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                      <img src={logo} alt="" />
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => {this.setState({showMobileNav: !this.state.showMobileNav})}}
                    >
                      <span className="navbar-icon"></span>
                      <span className="navbar-icon"></span>
                      <span className="navbar-icon"></span>
                    </button>

                    <div
                      className={this.state.showMobileNav ? "navbar-collapse active" : "navbar-collapse collapse"}
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                          <Link className="nav-link" to="/about">
                            About
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/features">
                            Features
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/pricing">
                            Pricing
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/faq">
                            FAQ
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/download">
                            Download
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/blog">
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NavBar
